import {Injectable} from '@angular/core';
import {
  Action,
  State,
  Selector,
  StateContext
} from '@ngxs/store';
import {UserStateModel} from './user-state.models';
import {UserStateActions} from "./user-state.actions";

const STATE_NAME = 'UserState';

@State<UserStateModel>({
  name: STATE_NAME,
  defaults: {
    email: '',
    isLoggedIn: false,
    profile: null,
    preferences: {
      language: "en",
      haptics: true,
      sounds: true,
      push: true
    }
  },
})
@Injectable()
export class UserState {
  @Selector()
  static user(state: UserStateModel): UserStateModel | null {
    return state;
  }

  @Selector()
  static pushNotifications(state: UserStateModel): boolean {
    return state.preferences.push;
  }

  @Selector()
  static isLoggedIn(state: UserStateModel): boolean {
    return state.isLoggedIn;
  }

  @Action(UserStateActions.SetEmail)
  setEmail(ctx: StateContext<UserStateModel>, action: UserStateActions.SetEmail) {
    ctx.patchState(<UserStateModel>({
      email: action.email,
    }));
  }

  @Action(UserStateActions.SetIsLoggedIn)
  setIsLoggedIn(ctx: StateContext<UserStateModel>, action: UserStateActions.SetIsLoggedIn) {
    ctx.patchState(<UserStateModel>({
      isLoggedIn: action.isLoggedIn
    }));
  }

  @Action(UserStateActions.SetProfile)
  setProfile(ctx: StateContext<UserStateModel>, action: UserStateActions.SetProfile) {
    ctx.patchState(<UserStateModel>({
      profile: action.profile
    }));
  }

  @Action(UserStateActions.SetPreferencesPush)
  setPreferencesPush(ctx: StateContext<UserStateModel>, action: UserStateActions.SetPreferencesPush) {
    const preferences = ctx.getState().preferences;
    preferences.push = action.push;
    ctx.patchState(<UserStateModel>({
      preferences: preferences
    }));
  }

  @Action(UserStateActions.SetPreferencesSounds)
  setPreferencesSounds(ctx: StateContext<UserStateModel>, action: UserStateActions.SetPreferencesSounds) {
    const preferences = ctx.getState().preferences;
    preferences.sounds = action.sounds;
    ctx.patchState(<UserStateModel>({
      preferences: preferences
    }));
  }

  @Action(UserStateActions.SetPreferencesHaptics)
  setPreferencesHaptics(ctx: StateContext<UserStateModel>, action: UserStateActions.SetPreferencesHaptics) {
    const preferences = ctx.getState().preferences;
    preferences.haptics = action.haptics;
    ctx.patchState(<UserStateModel>({
      preferences: preferences
    }));
  }

  @Action(UserStateActions.SetPreferencesLanguage)
  setPreferencesLanguage(ctx: StateContext<UserStateModel>, action: UserStateActions.SetPreferencesLanguage) {
    const preferences = ctx.getState().preferences;
    preferences.language = action.language;
    ctx.patchState(<UserStateModel>({
      preferences: preferences
    }));
  }

  @Action(UserStateActions.DeleteUser)
  deleteUser(ctx: StateContext<UserStateModel>) {
    ctx.patchState(<UserStateModel>({
      profile: null,
      email: '',
      isLoggedIn: false
    }));
  }


}

